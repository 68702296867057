<template>
  <!-- 首页[告警列表] -->
  <div class="alarm_content">
    <div class="alarm_date flexCB">
      <div class="date_chan">{{ todayYM }}</div>
      <div class="date_his flexC" @click="showDateEx">
        <span class="iconfont iconhisd"></span><span>历史告警</span>
      </div>
    </div>
    <div class="alarm_list boxOther">
      <div class="box100 listScroll">
        <div class="alListItem flexCB" v-for="(item,index) in alarmL" :key="index">
          <div class="ali_L">
            <p class="aliLT">{{ item.ectptime }}</p>
            <p class="aliLN">{{ item.ectpmark }}</p>
          </div>
          <div class="ali_R flexCE">
            <p :class="{aliT1:item.ectptype === '1',aliT2:item.ectptype ==='2',aliT3:item.ectptype ==='3', aliT4:item.ectptype ==='4'}"
               @click="showDetail(item)">{{ item.typename }}</p>
          </div>
        </div>
<!--        <div class="alListItem flexCB">-->
<!--          <div class="ali_L">-->
<!--            <p class="aliLT">2020.02.12 12:24:36</p>-->
<!--            <p class="aliLN">烟感发生隐患告警烟感发生隐患告e...</p>-->
<!--          </div>-->
<!--          <div class="ali_R flexCE"><p class="aliT2">隐患</p></div>-->
<!--        </div>-->
        <div class="notDataBox" v-if="alarmL.length === 0">
          <div>
            <img src="../../assets/img/noData.png" />
            <p>今日无告警</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dateBox" v-if="dateShow">
      <Calendar @selectCalendar="selectCalendar"></Calendar>
    </div>
  </div>
</template>

<script>
import { getAlarmList } from '@/http/api/home'
import { getTodayDate, formats } from '@/utils/validate'
import Calendar from '@/components/home/calendar'
export default {
  props: ['Refresh'],
  components: {
    Calendar
  },
  data () {
    return {
      companyid: '',
      today: '',
      todayYM: '',
      dateShow: false,
      alarmL: []
    }
  },
  watch: {
    Refresh (val) {
      this.getList()
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.today = getTodayDate()
    this.todayYM = formats(this.today)
    this.getList()
  },
  methods: {
    // 告警列表
    getList () {
      const params = {
        companyid: this.companyid,
        ectptime: this.today
      }
      getAlarmList(params).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            this.alarmL = res.data
          } else {
            this.alarmL = []
          }
        } else {
          this.alarmL = []
        }
      })
    },
    showDetail (item) {
      this.$emit('alarmNews', item)
    },
    // 展示日历
    showDateEx () {
      this.dateShow = !this.dateShow
    },
    // 选择日期
    selectCalendar (res) {
      this.dateShow = !this.dateShow
      this.today = res
      this.todayYM = formats(res)
      this.getList()
    }
  }
}
</script>

<style scoped>
  .alarm_content{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .alarm_date{
    width: 100%;
    height: 30px;
    position: relative;
  }
  .alarm_list{
    padding-top: 42px;
  }
  .listScroll{
    overflow-y: scroll;
    scrollbar-width: none;/* //火狐兼容 */
  }
  .listScroll::-webkit-scrollbar {
    width: 0px; /* 谷歌兼容 */
  }
  .date_chan{
    width: 75%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    background: rgba(136, 172, 252, 0.16);
    border-radius: 20px 0px 0px 20px;
  }
  .date_his{
    width: 24%;
    height: 30px;
    line-height: 30px;
    background: rgba(136, 172, 252, 0.16);
    border-radius: 0px 20px 20px 0px;
    color: #88ACFC;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    z-index: 10;
  }
  .date_his .iconfont{
    font-size: 26px;
  }
  .alListItem{
    width: 100%;
    height: 60px;
    padding: 0px 20px;
    box-sizing: border-box;
  }
  .alListItem:nth-child(odd){
    background: rgba(136, 172, 252, 0.16);
  }
  .ali_L{
    width: 78%;
  }
  .aliLT{
    line-height: 22px;
    font-size: 14px;
    font-weight: 600;
  }
  .aliLN{
    line-height: 22px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ali_R{
    width: 16%;
    height: 30px;
  }
  .ali_R p{
    width: 100%;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
  }
  .ali_R .aliT1{
    width: 70px;
    background: rgba(255, 76, 76, 0.1);
    border: 1px solid #FF4C4C;
    color: #FF4C4C;
  }
  .ali_R .aliT2{
    width: 50px;
    background: rgba(217, 48, 236, 0.1);
    border: 1px solid #D930EC;
    color: #D930EC;
  }
  .ali_R .aliT3{
    width: 50px;
    background: rgba(255, 148, 76, 0.1);
    border: 1px solid #FF944C;
    color: #FF944C;
  }
  .ali_R .aliT4{
    width: 50px;
    background: rgba(52, 118, 243, 0.1);
    border: 1px solid #3476F3;
    color: #3476F3;
  }
  .notDataBox{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(136, 172, 252, 0.16);
    color: #A5B7CC;
  }
  .dateBox{
    width: 400px;
    height: 370px;
    background: #061A34;
    border: 1px solid #42EEFF;
    position: absolute;
    top: 42px;
    right: 0px;
    border-radius: 8px;
    /*padding: 30px;*/
    /*box-sizing: border-box;*/
  }
</style>
