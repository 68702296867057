<template>
<!-- 首页[设备分析][告警统计] -->
  <div class="equanaContent">
    <div class="homeItemBg flexAC">
      <span class="el-icon-caret-right qctIco"></span>
      <span class="qctTitle">设备分析</span>
    </div>
    <div class="eqc_content flexCB">
      <div class="eqcc_L">
        <p class="eqcc_T">设备总数</p>
        <p class="eqcc_N">{{ equN.equcount }}</p>
      </div>
      <div class="eqcc_R flexAC">
        <div class="eqccEchart" ref="online"></div>
        <div class="eqccEchart" ref="offline"></div>
      </div>
    </div>

    <!--  告警统计  -->
    <div class="homeItemBg flexAC">
      <span class="el-icon-caret-right qctIco"></span>
      <span class="qctTitle">告警统计</span>
    </div>
    <div class="alarm_content flexCB">
      <div class="ala_item flexAC">
        <p class="aiLogo"><img src="../../assets/img/home_at.png"/></p>
        <div class="aiInfo">
          <p>今日告警</p><p class="aiNum">{{ alarmN.today_cnum }}</p>
        </div>
      </div>
      <div class="ala_item flexAC">
        <p class="aiLogo"><img src="../../assets/img/home_ah.png"/></p>
        <div class="aiInfo">
          <p>历史告警</p><p class="aiNum">{{ alarmN.allday_cnum }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEquAllNum, getAlarmNum } from '@/http/api/home'
var echarts = require('echarts')
export default {
  props: ['Refresh'],
  data () {
    return {
      onlineEcharts: '',
      onlineNum: 80,
      offlineEcharts: '',
      offlineNum: 60,
      companyid: '',
      equN: '',
      alarmN: ''
    }
  },
  watch: {
    Refresh (val) {
      this.getEquNum() // 设备总数
      this.getAlarmAll() // 告警总数
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getEquNum() // 设备总数
    this.getAlarmAll() // 告警总数
  },
  methods: {
    // 设备总数
    getEquNum () {
      getEquAllNum({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.equN = res.data
          this.getOnline() // 在线
          this.getOffline() // 离线
        } else {
          this.equN = ''
        }
      })
    },
    // 在线图表
    getOnline () {
      const num = (Number(this.equN.isequcfonf) / Number(this.equN.equcount)) * 100
      this.onlineNum = num
      this.onlineEcharts = echarts.init(this.$refs.online)
      const option = {
        title: {
          show: true,
          text: '在线',
          left: 18,
          textStyle: { color: '#fff', fontSize: 12, lineHeight: 62 }
        },
        series: [
          {
            type: 'pie',
            radius: ['0%', '80%'],
            hoverAnimation: false,
            itemStyle: { normal: { color: 'rgba(136, 172, 252, 0.16)', labelLine: { show: false } } },
            data: [{ value: 0 }]
          },
          {
            type: 'pie',
            radius: ['60%', '100%'],
            hoverAnimation: false,
            roseType: 'radius',
            data: [
              {
                name: '在线',
                value: this.onlineNum,
                hoverAnimation: false,
                label: { normal: { show: false } },
                itemStyle: {
                  borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#4294FF' },
                      { offset: 1, color: '#42EFFF' }
                    ])
                  }
                }
              },
              {
                name: '在线',
                hoverAnimation: false,
                value: 100 - this.onlineNum,
                label: { normal: { show: false } },
                itemStyle: { color: 'rgba(136, 172, 252, 0.01)' }
              }
            ]
          }
        ]
      }
      this.onlineEcharts.setOption(option)
      // this.onlineEcharts.dispatchAction({ type: 'highlight', seriesIndex: 1, dataIndex: 0 })
    },
    // 离线图表
    getOffline () {
      const num = (Number(this.equN.noequcfonf) / Number(this.equN.equcount)) * 100
      this.offlineNum = num
      this.offlineEcharts = echarts.init(this.$refs.offline)
      const option = {
        title: {
          show: true,
          text: '离线',
          left: 18,
          textStyle: { color: '#fff', fontSize: 12, lineHeight: 62 }
        },
        series: [
          {
            type: 'pie',
            radius: ['0%', '80%'],
            hoverAnimation: false,
            itemStyle: { normal: { color: 'rgba(136, 172, 252, 0.16)', labelLine: { show: false } } },
            data: [{ value: 0 }]
          },
          {
            type: 'pie',
            radius: ['60%', '100%'],
            hoverAnimation: false,
            data: [
              {
                name: '离线',
                value: this.offlineNum,
                label: { normal: { show: false } },
                itemStyle: {
                  borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#FF6242' },
                      { offset: 1, color: '#FFAD42' }
                    ])
                  }
                }
              },
              {
                name: '离线',
                hoverAnimation: false,
                value: 100 - this.offlineNum,
                label: { normal: { show: false } },
                itemStyle: { color: 'rgba(136, 172, 252, 0.01)' }
              }
            ]
          }
        ]
      }
      this.offlineEcharts.setOption(option)
    },
    // 设备总数
    getAlarmAll () {
      getAlarmNum({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.alarmN = res.data
        } else {
          this.alarmN = ''
        }
      })
    }
  }
}
</script>

<style scoped>
.equanaContent{
  width: 100%;
  height: 100%;
  position: relative;
}
.eqc_content{
  width: 100%;
  height: 130px;
  position: relative;
  padding: 30px 6px;
  box-sizing: border-box;
}
.alarm_content{
  width: 100%;
  height: 100px;
  position: relative;
  padding: 30px 6px;
  box-sizing: border-box;
}
.eqcc_L{
  width: 40%;
  color: #FFFFFF;
}
.eqcc_R{
  width: 60%;
  height: 100%;
}
.eqcc_T{
  font-size: 14px;
}
.eqcc_N{
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 2px;
}
.eqccEchart{
  width: 70px;
  height: 70px;
  /*border: 1px solid #fff;*/
  border-radius: 50px;
  margin-right: 50px;
}
.eqccEchart:last-child{
  margin-right: 0px;
}
.ala_item{
  width: 50%;
  height: 100%;
}
.aiLogo img{
  width: 40px;
  height: 40px;
}
.aiInfo{
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  margin-left: 15px;
}
.aiNum{
  font-size: 18px;
}
</style>
