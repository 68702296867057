<template>
  <div class="date_cont">
    <div class="date_top flexCB">
      <div class="dateYM">{{ year }}年{{ month }}月</div>
      <div class="monthBox">
        <span class="iconfont iconxiangxia" @click="nextMonth"></span>
        <span class="iconfont iconxiangshang" @click="proveMonth"></span>
      </div>
    </div>
    <div class="weekBox">
      <p v-for="item in weeks" :key="item.index">{{item.name}}</p>
    </div>
    <div class="schedule_day">
      <div class="dayBox" v-for="(item, index) in Total" :key="index">
        <li class="dayLi">
          <p v-if="(index+1-StartWeek) <= 0" class="pmday">{{ (index+1-StartWeek)+LastMonthDay }}</p>
          <p v-if="(index+1-StartWeek) > 0 && index+1-StartWeek <= FullDay" class="dayCss"
          :class="{todayStyle:today === (year+'-'+(month<10?'0'+month:month)+'-'+(((index+1-StartWeek)<10)?('0'+(index+1-StartWeek)):(index+1-StartWeek)))}"
          @click="dateSelect(index+1-StartWeek)">
            {{ (index+1)-StartWeek }}
            <span :class="getNum(index+1-StartWeek) === true ? 'haveNum' : ''"></span>
          </p>
          <p v-if="index+1-StartWeek > FullDay" class="nmday">{{ (index+1-StartWeek) - FullDay }}</p>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import { alarmMonth } from '@/http/api/home'
export default {
  name: 'calendar',
  data () {
    return {
      companyid: '',
      year: '', // 年
      month: '', // 月
      day: '', // 日
      weeks: [], // 星期
      today: '',
      FullDay: '', // 当月总天数
      StartWeek: '', // 当月第一天是周几,
      Total: '', // 总数
      LastMonthDay: '',
      alarmListNumber: []
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    var time = new Date()
    this.year = time.getFullYear()
    this.month = time.getMonth() + 1
    this.day = time.getDate()
    this.weeks = [{ name: '日' }, { name: '一' }, { name: '二' }, { name: '三' }, { name: '四' }, { name: '五' }, { name: '六' }]
    this.today = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-' + (this.day < 10 ? '0' + this.day : this.day)
    // console.log(this.year)
    // console.log(this.month)
    // console.log(this.day)
    // console.log(this.today)
    this.getCalendar()

    // this.getDateList()
  },
  methods: {
    // 获取总天数
    getCalendar () {
      this.FullDay = new Date(this.year, this.month, 0).getDate()
      this.StartWeek = new Date(this.year, this.month - 1, 1).getDay()
      // console.log(this.FullDay)
      // console.log(this.StartWeek)
      this.Total = ((this.FullDay + this.StartWeek) % 7 === 0 ? (this.FullDay + this.StartWeek) : this.FullDay + this.StartWeek + (7 - (this.FullDay + this.StartWeek) % 7)) // 元素总个数
      this.LastMonthDay = new Date(this.year, (this.month) - 1, 0).getDate() // 上月最后一天
      // console.log(this.Total)
      // console.log(this.LastMonthDay)
      this.getDateList()
    },
    getDateList () {
      const params = {
        companyid: this.companyid,
        years: this.year,
        month: this.month
      }
      alarmMonth(params).then(res => {
        if (res.status === '1') {
          this.alarmListNumber = res.data
          // console.log(this.alarmListNumber)
        } else {
          this.alarmListNumber = []
        }
      })
    },
    getNum (i) {
      var isOk = ''
      for (var j = 0; j < this.alarmListNumber.length; j++) {
        if (i === this.alarmListNumber[j].sort) {
          isOk = this.alarmListNumber[j].isok
        }
      }
      return isOk
    },
    // 切换上个月
    proveMonth () {
      if (this.month - 2 < 0) {
        this.year -= 1
        this.month = 12
      } else {
        this.month -= 1
      }
      this.getCalendar()
    },
    // 切换下个月
    nextMonth () {
      if (this.month > 11) {
        this.year += 1
        this.month = 1
      } else {
        this.month += 1
      }
      this.getCalendar()
    },
    dateSelect (i) {
      // console.log(i)
      var m = this.month < 10 ? '0' + this.month : this.month
      var d = i < 10 ? '0' + i : i
      var t = this.year + '-' + m + '-' + d
      // console.log(t)
      this.$emit('selectCalendar', t)
    }
  }
}
</script>

<style scoped>
.date_cont{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 30px 0px;
  box-sizing: border-box;
}
.date_top{
  padding: 0px 30px;
}
.dateYM{
  font-size: 16px;
  color: #6F7E90;
}
.monthBox .iconfont{
  color: #6F7E90;
  font-size: 20px;
  margin-left: 30px;
  cursor: pointer;
}
.weekBox{
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  margin: 20px 0px;
}
.weekBox p{
  width: 14.28%;
  text-align: center;
}
.schedule_day{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
  box-sizing: border-box;
}
.dayBox{
  width: 14.28%;
  height: 38px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  list-style: none;
  font-size: 14px;
  padding-top: 4px;
}
.dayLi{
  width: 100%;
  height: 100%;
  position: relative;
}
.dayCss{
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}
.todayStyle{
  background: rgba(136, 172, 252, 0.16);
  border-radius: 50%;
}
.nmday,.pmday{
  color: #6F7E90;
  height: 30px;
  line-height: 30px;
}
.haveNum{
  width: 4px;
  height: 4px;
  background: #FF0000;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 4px;
}
</style>
